.App {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Passion One", sans-serif;
  min-height: 100vh;
  min-width: 100vw;

}

.headers {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'DM Serif Display';
  min-height: 100vh;
}




.navigation {
  background-color: black;
  display: flex;
  font-size: calc(10px + 2vmin);
  font-family: 'Seymour One';
  padding: 25px
}


.body {
  background-color: black;
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
  min-width: 100vw;
  justify-content: center;
}

.gallery {
  background-color: black;
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
  justify-content: center;
}